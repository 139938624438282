<template>
  <section>
   <carousel :loop="true" :autoplay="true" :autoplayHoverPause="true" :autoplayTimeout="3000" :perPage="1" :navigationEnabled="true" :paginationEnabled="false" :navigationNextLabel="navLabel" :navigationPrevLabel="navLabel">
      <slide v-for="(activity, i) in activitiesV2" :key="i">
        <p class="title">{{activity.title}}</p>
        <div class="content">
          <img :src="activity.icon_uri" height="240px" />
          <p class="sub-title">{{activity.sub_title}}</p>
          <p class="time">时间: {{activity.start_time}} <span v-if="activity.end_time">-- {{activity.end_time}}</span></p>
          <p class="address">地址: {{activity.address}}</p>
        </div>
      </slide>
    </carousel>
  </section>
</template>
<script>
  import { Carousel, Slide } from 'vue-carousel'
  import { getActivities } from "@/api/api.js"
  import { actList } from "./enum"

  export default {
    components:{Carousel,Slide},
    data() {
      return {
        activities: [],
        activitiesV2:actList,
        timer: null,
        navLabel: '<span class="icon-zuohua"></span>'
      }
    },
    destroyed() {
      clearInterval(this.timer);
    },
    mounted() {
      getActivities().then((res) => {
        this.activities = res.data.data.list;
      });
      this.timer = setInterval(this.scrollAnimate, 60000);
    },
  };
</script>
<style scoped>
  .title {
    text-align: center;
    font-size: 24px;
  }
  .content img {
    float:left;
    padding:5px;
  }
  .sub-title{font-size: 24px;color:#fff;line-height: 44px;margin-top:15px}
  .time, .address {
    margin-top: 10px;
  }
</style>