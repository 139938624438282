
import act1 from "@/assets/guo-da-nian.jpeg"
import act2 from "@/assets/hua-zhan.jpeg"
import act3 from "@/assets/qi-yuan-qiang.jpeg"
import act4 from "@/assets/xun-you.jpeg"

export const actList = [
  { 
    icon_uri: act1,
    title: '祝福绍兴古城过大年活动',
    sub_title:'流光溢彩添华锦，火树银花不夜天。鲁迅故里高品质步行街，新春灯会+祝福绍兴古城过大年新春嘉年华，亮点抢先看，独家定制年味满满，闹花灯、逛庙会、观演艺、抢消费券。带你沉浸式体验新春嘉年华！', 
    start_time: '1月17日', 
    end_time: '2月5日', 
    address: '鲁迅中路'
  },
  { 
    icon_uri: act2,
    title: '独立画展',
    sub_title:'独立插画师李大伟x绍兴首届户外插画展。刷爆小红书和朋友圈的中国红“国潮范局x大伟插画”惊喜现身鲁迅故里步行街。来鲁迅故里高品质步行街打卡！', 
    start_time: '1月17日', 
    end_time: '1月28日', 
    address: '鲁迅中路'
  },
  { 
    icon_uri: act3,
    title: '祈愿墙',
    sub_title:'春节祈福是中华民族的传统，每一个人都希望家人平平安安。当我们置身于祈福牌下，有风吹过时，小木牌之间相互碰撞发出摩擦，传出的是木牌低沉又浑厚的声音。', 
    start_time: '1月17日', 
    end_time: '2月5日', 
    address: '鲁迅中路'
  },
  { 
    icon_uri: act4,
    title: '宋韵巡游活动',
    sub_title:'从青砖黛瓦的鲁迅中路，到曲巷幽静的府河街，鲁迅故里步行街启动宋韵巡游活动，身穿汉服的小哥哥小姐姐们衣袂飘飘，自成一道风景，快来和他们一起互动吧！', 
    start_time: '1月17日19:20/20:40，1月26日18:00/20:00，2月5日18:00/20:00', 
    end_time: '', 
    address: '府河街'
  },
]