<template>
  <section>
    <carousel :loop="true" :autoplay="true" :autoplayHoverPause="true" :autoplayTimeout="3000" :perPage="1" :navigationEnabled="true" :paginationEnabled="false" :navigationNextLabel="navLabel" :navigationPrevLabel="navLabel">
      <slide v-for="(img, i) in imgs" :key="i">
        <img height='650px' width="100%" :src="img.url" />
        <p class="name">{{img.name}}</p>
      </slide>
    </carousel>
  </section>
</template>
<script>
import { Carousel, Slide } from 'vue-carousel'
export default {
  components:{Carousel,Slide},
  data() {
    return {
      imgs: [
        {url: "https://luxunlu-1254300759.cos.ap-shanghai-fsi.myqcloud.com/panoramas/东门入口.jpg", name: "东入口"},
        {url: "https://luxunlu-1254300759.cos.ap-shanghai-fsi.myqcloud.com/panoramas/咸亨酒店.jpg", name: "咸亨酒店"},
        {url: "https://luxunlu-1254300759.cos.ap-shanghai-fsi.myqcloud.com/panoramas/壁画.jpg", name: "壁画"},
        {url: "https://luxunlu-1254300759.cos.ap-shanghai-fsi.myqcloud.com/panoramas/星巴克.jpg", name: "星巴克"},
        {url: "https://luxunlu-1254300759.cos.ap-shanghai-fsi.myqcloud.com/panoramas/里仁.jpg", name: "仁里"},
        {url: "https://luxunlu-1254300759.cos.ap-shanghai-fsi.myqcloud.com/panoramas/鲁迅纪念馆.jpg", name: "鲁迅纪念馆"},
        {url: "https://luxunlu-1254300759.cos.ap-shanghai-fsi.myqcloud.com/panoramas/延安路口.jpg", name: "延安路口"},
        {url: "https://luxunlu-1254300759.cos.ap-shanghai-fsi.myqcloud.com/panoramas/府河街入口.jpg", name: "府河街入口"},
        {url: "https://luxunlu-1254300759.cos.ap-shanghai-fsi.myqcloud.com/panoramas/铜像广场.jpg", name: "铜像广场"},
      ],
      navLabel: '<span class="icon-zuohua"></span>'
    };
  },
};
</script>
<style scoped>
  .VueCarousel-slide{text-align: center;}
  ::v-deep .icon-zuohua{color:#a9a9a9;font-size: 32px;}
  ::v-deep .VueCarousel-navigation-prev{transform: translateY(-50%) translateX(0%)}

  ::v-deep .VueCarousel-navigation-next{transform: translateY(-50%) translateX(0%) rotate(180deg)}
  ::v-deep .VueCarousel-navigation--disabled{opacity: .2;}
  ::v-deep .VueCarousel-navigation-button:focus{outline: none}

  .name{font-size: 24px;}
</style>