<template>
  <section class="store-info">
    <div class="store-list">
      <carousel :loop="true" :autoplay="true" :autoplayHoverPause="true" :autoplayTimeout="3000" :perPage="1" :navigationEnabled="true" :paginationEnabled="false" :navigationNextLabel="navLabel" :navigationPrevLabel="navLabel">
        <slide v-for="(activity, i) in activities2" :key="i" class="coupon-slide">
          <img :src="activity.logo" class="responsive-img">
          <p class="name">{{ activity.title }}</p>
        </slide>
      </carousel>
    </div>
  </section>
</template>
<script>
import { Carousel, Slide } from 'vue-carousel'
import { getNotices } from "@/api/api.js";
import logo from "@/assets/jie-qu-gong-gao.jpg"

export default {
  components:{Carousel,Slide},
  data() {
    return {
      timer: 0,
      activities: [],
      activities2: [
        {logo: logo, title: '优惠券领取流程'}
      ],
      navLabel: '<span class="icon-zuohua"></span>'
    };
  },
  methods: {
    getData() {
      getNotices().then((res) => {
        this.activities = res.data.data.data;
      });
    }
  },
  mounted() {
    this.getData()
    this.timer = setInterval(this.getData, 10000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
};
</script>
<style scoped>
  .VueCarousel-slide{text-align: center;}
  .responsive-img{height:240px;border-radius: 8px;}
  ::v-deep .icon-zuohua{color:#a9a9a9;font-size: 32px;}
  ::v-deep .VueCarousel-navigation-prev{transform: translateY(-50%) translateX(0%)}

  ::v-deep .VueCarousel-navigation-next{transform: translateY(-50%) translateX(0%) rotate(180deg)}
  ::v-deep .VueCarousel-navigation--disabled{opacity: .2;}
  ::v-deep .VueCarousel-navigation-button:focus{outline: none}
</style>
