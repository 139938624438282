<template>
  <section>
    <carousel :loop="true" :autoplay="true" :autoplayHoverPause="true" :autoplayTimeout="3000" :perPage="1" :navigationEnabled="true" :paginationEnabled="false" :navigationNextLabel="navLabel" :navigationPrevLabel="navLabel">
      <slide v-for="(item, i) in jingDians" :key="i" class="coupon-slide">
        <div>
          <img :src="item.img" class="img" width="360px" height="260px" />
          <span>
              街区建成于20世纪80年代，街区贯穿绍兴标志性人文景观，国家“5A”级景区——鲁迅故里。整条街区总长约1130米，主街宽度为10米，成“L”字形，项目总建筑面积达20万方。从文化IP、互动体验、美学场景、功能升级、智慧街区、夜光经济六大方面进行街区提升，设置景观灯带、互动投影、智能景观柱，优化步行区域、建设全新游客服务中心等。鲁迅故里作为绍兴核心文商旅项目，将成为集时尚、购物、餐饮、娱乐、体验为一体的商业街区。
          </span>
        </div>
      </slide>
    </carousel>
  </section>
</template>
<script>
import { Carousel, Slide } from 'vue-carousel'
import { getImages } from "@/api/api.js";
import jingDian1 from "@/assets/jingDian-1.jpg"
import jingDian2 from "@/assets/jingDian-2.jpg"
import jingDian3 from "@/assets/jingDian-3.jpg"

export default {
  components:{Carousel,Slide},
  data() {
    return {
      jingDians: [
        {
          img: jingDian1,
          desc: "",
        },
        {
          img: jingDian2,
          desc: "",
        },
        {
          img: jingDian3,
          desc: "",
        },
      ]
    };
  },
  mounted() {
    getImages().then((res) => {
      console.log(res.data);
    });
  },
};
</script>
<style scoped>
.flex {
  justify-content: space-around;
}
/* .images {
  padding: 0 30px;
}
.images img {
  border-radius: 5px;
}
.images li {
  width: 60%;
}*/
.img {
  /* width: 70%; */
  float: left;
  margin: 10px;
}
div span{
  /* margin-top: 40px;
  padding-top: 40px; */
  /* text-align: justify; */
  font-size: 15px;
  color: #fff;
}
</style>
